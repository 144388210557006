
/** 路由配置 */
const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home/index.vue')
  }, {
    path: '/product',
    name: 'product',
    component: () => import('@/views/product/index.vue'),
  },  {
    path: '/cases',
    name: 'cases',
    component: () => import('@/views/cases/index.vue'),
  },{
    path: '/custom',
    name: 'custom',
    component: () => import('@/views/custom/index.vue')
  }, {
    path: '/solution',
    name: 'solution',
    component: () => import('@/views/solution/index.vue')
  }, {
    path: '/about',
    name: 'about',
    component: () => import('@/views/about/index.vue')
  }, {
    path: '/news',
    name: 'news',
    component: () => import('@/views/news/index.vue'),
  },{
    path: '/news/detail',
    name: 'news-detail',
    component: () => import('@/views/news/detail/index.vue'),
  }
]

export default routes