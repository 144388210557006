
/** 项目配置 */
module.exports = {

	title: '喜望软件',

	baseURL: process.env.NODE_ENV === 'development'
		? 'https://admin-php/api'
		: 'https://admin.1hoper.com/api',

	requestTimeout: 3 * 1000,

	contentType: 'application/json',

	storage: 'localStorage',

	tokenName: 'token',

	tokenTableName: 'token',

	remUnit: 192,
}